div.relative {
  position: relative;
  top: 100px;
  border: 1px solid #563abb;
}

div.loadin-main-container {
  position: relative;
  top: 100px;
  border: 0px solid #563abb;
}

div.loading {
  position: relative;
  top: 400px;
  border: 1px solid #563abb;
}

div.cache-table {
  position: relative;
  top: 100px;
  border: 1px solid #563abb;
  padding-left: 20%;
  padding-right: 20%;
}

div.service-config-table {
  position: relative;
  top: 100px;
  border: 1px solid #563abb;
  padding-left: 10%;
  padding-right: 10%;
}

div.form {
  position: relative;
  top: 125px;
  border: 0px solid #563abb;
  padding-left: 20%;
  padding-right: 20%;
}

.ReactTable .rt-thead .rt-tr-align-center {
  text-align: center;
}
