.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.avatar {
  color: #cada2a;
}

.root {
  color: white;
}
.icon {
  color: white;
}
