div.root {
  display: flex;
  justify-content: space-between;
  height: 100px;
  padding: 0 2em;
  margin: 0.5em 0;
}

.appbar {
  background: "#004d99";
}
